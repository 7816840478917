import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogData {
  // code: any;
}
@Component({
  selector: 'app-social-popup',
  templateUrl: './social-popup.component.html',
  styleUrls: ['./social-popup.component.scss'],
})
export class SocialPopupComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SocialPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,) { }

  ngOnInit() {}
  close(){
    this.dialogRef.close('!ye ho gya band');
  }


}
