import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../core/config';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastComponent } from '../../shared/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {
  apiUrl : string = Config.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
    public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent
  ) {
    this.headers = new Headers();

    this.headers.append('Access-Control-Allow-Origin' , '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept','application/json');
    this.headers.append('content-type','application/json');
    this.getLoginUserData();
   }

   getLoginUserData(){
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if(loginUserData){
      this.accessToken = loginUserData['id'];
      this.userId = loginUserData['userId'];
    }
    
  }


  getserviceDetails(serviceIdDetail){

    this.getLoginUserData();

    this.loadingService.showLoading();
  
  
    let profileURL = this.apiUrl + 'services/' +serviceIdDetail
                  + '?access_token=' + this.accessToken;

    return new Promise((resolve, reject) => {
      this.http.get(profileURL).subscribe(res => {
      
        this.loadingService.hideLoading();
            resolve(res);
      },(err)=>{
        this.loadingService.hideLoading();
            this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
            reject(err);
      });
    });
  }


  getAllServices(serviceId,location){
    this.getLoginUserData();

  this.loadingService.showLoading();

  let filterJSON = {		
      "include":[
        {
          "relation":'subServices',
          "scope":{
            "fields":["id","service_Name","serviceBannerImage","measureableUnitId"],
            "where":{
              "status":true,
              "areaId":{"like":location}
            },
            "limit":1
            // "include":{
            //   "relation":"subServices",
            //   "scope":{
            //     "fields":["id","service_Name","serviceBannerImage","measureableUnitId"],
            //     "where":{
            //       "status":true,
            //       "areaId":{"like":location}
            //     },  
            //   }
            // }
          }
    }
  ],
      "order": "sequence DESC",
      "where":{
        "parentId":serviceId,
        "status":true ,
        "areaId":{"like":location}
      },
      "fields":["id","service_Name","sequence","subServices","measureableUnitId","serviceBannerImage"]
    };
  

  let filter = encodeURI(JSON.stringify(filterJSON));

  let allServiceURL = this.apiUrl + 'services/' 
                + '?access_token=' + this.accessToken
                + '&filter=' + filter;

  return new Promise((resolve, reject) => {
    this.http.get(allServiceURL).subscribe((res:any) => {
      
        this.loadingService.hideLoading();
        resolve(res);
      
    },(err)=>{
      this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
          reject(err);
    });
  });
}


searchServices(filterJSON){
  this.getLoginUserData();

this.loadingService.showLoading();

let filter = encodeURIComponent(JSON.stringify(filterJSON));

let allServiceURL = this.apiUrl + 'services/' 
              + '?access_token=' + this.accessToken
              + '&filter=' + filter;

return new Promise((resolve, reject) => {
  this.http.get(allServiceURL).subscribe((res:any) => {
    
      this.loadingService.hideLoading();
      resolve(res);
    
  },(err)=>{
    this.loadingService.hideLoading();
        this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
        reject(err);
  });
});
}


getAllServicesforplan(){
  this.getLoginUserData();

  this.loadingService.showLoading();
  let filterJSON = {		
      "order": "createdAt DESC",
      "where":{"parentId":"0"},
      "include":{
      "relation" : "subServices",
      "scope" : {
        "include" : {
          "relation" : "subServices",
          "scope" : {
            "include" : {
              "relation" : "subServices"
            }
          }
        }
      }
    }
    };
  

  let filter = encodeURI(JSON.stringify(filterJSON));

  let allServiceURL = this.apiUrl + 'services/' 
                + '?access_token=' + this.accessToken
                + '&filter=' + filter;

  return new Promise((resolve, reject) => {
    this.http.get(allServiceURL).subscribe((res:any) => {
      
        this.loadingService.hideLoading();
        resolve(res);
      
    },(err)=>{
      this.loadingService.hideLoading();
      this.snackbarCmp.presentToast(Config.GENERIC_ERROR);
      reject(err);
    });
  });
}



getAllsettings(){
  this.getLoginUserData();

this.loadingService.showLoading();
let filterJSON = {		

  "order": "createdAt DESC"
};


let filter = encodeURI(JSON.stringify(filterJSON));



let allQueryURL = this.apiUrl + 'settings/' 
              + '?access_token=' + this.accessToken
              +'&filter=' + filter

return new Promise((resolve, reject) => {
  this.http.get(allQueryURL).subscribe((res:any) => {
    
      this.loadingService.hideLoading();
      resolve(res);
    
  },(err)=>{
    this.loadingService.hideLoading();
        this.snackbarCmp.presentToastError("Something went wrong");
        reject(err);
  });
});
}



selectedAddress : any;
updateLocation(){
  this.getLoginUserData();


return new Promise((resolve, reject) => {
  let areaService;
  let allAddress ; 
  let place;
  let address;
  let latitude;
  let longitude;
  areaService.getEveryAreas().then((res)=>{
		console.log(res);
    allAddress = res;
  place = localStorage.getItem('location');
    
		if(!localStorage.getItem('locationId')){
			console.log("adf")
			let valu = 0;
			allAddress.map((value,index)=>{
        
				if(this.arePointsNear({lat:value.position.lat,lng:value.position.long},{lat:latitude,lng:longitude},50)){
					valu = 1;
					this.selectedAddress = value;
					this.changeLocation()
				}	
				if((index == (allAddress.length  -1)) && (valu == 0)){
					this.selectedAddress = allAddress[allAddress.length - 1];
					this.changeLocation()
				}
			})
		}
		console.log(address);
		
		allAddress.map((element)=>{
      element.breifAddress=element.areaObj.formatted_address.split(',');
      if(element.city == place){
        this.selectedAddress = element
      }
			// console.log(element.breifAddress)
			// console.log(element.breifAddress.length)
			

			element.breifAddress=element.breifAddress[element.breifAddress.length-3] + 
			 ','+element.breifAddress[element.breifAddress.length-2] 
			 + ','+element.breifAddress[element.breifAddress.length-1]
			// console.log(element.breifAddress)

		})
	  })
    
      resolve({});
        reject({});
  
});
}

changeLocation(){
  localStorage.setItem("location",this.selectedAddress.city);
  localStorage.setItem('locationId',this.selectedAddress.id);
  // this.modal.dismiss();
  }

arePointsNear(checkPoint, centerPoint, km) {
  var ky = 40000 / 360;
  var kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
  var dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
  var dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= km;
  }

		
 


}
