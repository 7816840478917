import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../core/config';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastComponent } from '../../shared/toast/toast.component';

@Injectable({
  providedIn: 'root'
})
export class QueryService {
  apiUrl : string = Config.API_ENDPOINT;
	loginData: any;
	headers : any;
	accessToken : any;
	userId : any;
  constructor(
    public http: HttpClient,
  	public loadingService : LoaderService,
  	public snackbarCmp : ToastComponent
  ) {
    this.headers = new Headers();

    this.headers.append('Access-Control-Allow-Origin' , '*');
    this.headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
    this.headers.append('Accept','application/json');
    this.headers.append('content-type','application/json');
    this.getLoginUserData();
   }

   getLoginUserData(){
    let loginUserData = JSON.parse(localStorage.getItem("loginUser"));
    if(loginUserData){
      this.accessToken = loginUserData['id'];
      this.userId = loginUserData['userId'];
    }
    
  }
  addQuery(TransactionInfo) {
    this.getLoginUserData();
      this.loadingService.showLoading();
  
      let addTransactionURL = this.apiUrl + 'queries/' 
              + '?access_token=' + this.accessToken
     
  
    
    return new Promise((resolve, reject) => {
  
      this.http.post(addTransactionURL, TransactionInfo,{headers:this.headers,withCredentials: true})
        .subscribe(res => {
            this.loadingService.hideLoading();
            // this.dialog.openContentDialog(Config.MESSAGE.USER.ADD_TITLE,Config.MESSAGE.USER.ADD);
            // this.snackbarCmp.presentToast(Config.MESSAGE.USER.ADD);
          resolve(res);
        }, (err) => {
          this.loadingService.hideLoading();
          this.snackbarCmp.presentToast(Config.MESSAGE.USER.SIGNUP_ERR);
          reject(err);
        });
    });
  }
}
