import { Component, Inject, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NavController } from '@ionic/angular';
import { ToastComponent } from 'src/app/shared/toast/toast.component';
import { QueryService } from 'src/app/services/query/query.service';
import { AreaService } from 'src/app/services/Area/area.service';
import { CallNumber } from '@ionic-native/call-number/ngx';

@Component({
  selector: 'app-something',
  templateUrl: './something.component.html',
  styleUrls: ['./something.component.scss'],
})
export class SomethingComponent implements OnInit {
  
  dataModel:any;
  userData:any;
  errorFlag : boolean = false;
emailError : boolean = false;
  constructor(public dialogRef: MatDialogRef<SomethingComponent>,
    private navCtrl:NavController,
    private callNumber:CallNumber,
    private toast:ToastComponent,
   private queryService:QueryService,
   private areaService:AreaService) { 
    this.userData=JSON.parse(localStorage.getItem('userProfile'))

    this.dataModel={
      fullName:this.userData?.fullName,
      email:"",
      mobileNo:this.userData?.mobileNo,
      status:"Pending",
      profileImage:this.userData?.profileImage.url,
      message:"",
      userId:this.userData?.id,
    requirement : true,

   }

  }

  close(){
    this.dialogRef.close('!ye ho gya band');
  }
  ngOnInit() {
  }





 goback(){
  this.navCtrl.navigateBack('/app/about');
}
submit(){
  if(this.dataModel.fullName && this.dataModel.email && this.dataModel.message){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(re.test(this.dataModel.email)){
    this.queryService.addQuery(this.dataModel).then((res:any)=>{
      this.toast.presentToastError("Your Requirement Submitted Successfully")
  
      this.close();
    })
  }else{
    this.emailError = true;
    // this.toast.presentToastError("Please Enter Valid Email")

  }
  }else{
    this.errorFlag = true;
    // this.toast.presentToastError("Please Fill All The Fields")

  }
}
ionViewDidEnter() {
  this.areaService.showHome=false

}

call(){
  this.callNumber.callNumber("+916306023551", true)
  .then(res => console.log('Launched dialer!', res))
  .catch(err => console.log('Error launching dialer', err));
}

}
