import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ScrolleffectDirective } from './directives/scrolleffect.directive';
import { AlertComponent } from './shared/alert/alert.component';
import { SetLocationComponent } from './shared/set-location/set-location.component';
import { ToastComponent } from './shared/toast/toast.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {  MatCheckboxModule } from '@angular/material/checkbox';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatRadioModule } from '@angular/material/radio';
import { AgmCoreModule } from '@agm/core';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { AngularFireModule } from '@angular/fire';
import { Calendar } from '@ionic-native/calendar/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { SocialPopupComponent } from './pages/social-popup/social-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SomethingComponent } from './pages/something/something.component';
import {MatFormFieldModule,  } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import {MatIconModule} from '@angular/material/icon';



const firebaseConfig = environment.firebase;
export function playerFactory() {
  return player;
}
@NgModule({
  declarations: [
    AppComponent,
    ScrolleffectDirective,
    ToastComponent,
    AlertComponent,
    SetLocationComponent,
    SocialPopupComponent,
    SomethingComponent
  ],
  entryComponents: [SetLocationComponent, SocialPopupComponent , SomethingComponent],
  imports: [
    BrowserModule, 
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    IonicModule,
    HttpClientModule,
    IonicModule.forRoot(), 
    IonicModule.forRoot(),
    AppRoutingModule,
    LottieModule.forRoot({ player: playerFactory }),
    AngularFireModule.initializeApp(firebaseConfig), 
    MatButtonModule,
    MatRadioModule,
    AgmCoreModule.forRoot({
    apiKey: 'AIzaSyB0pYUlir5lQ_vxEGAFlFKsrrjWm50qMzY', libraries: ["places"]
    }),
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatSelectModule,
    AppRoutingModule,
    MatDialogModule
  ],
  providers: [
    StatusBar,
    FCM,
    LocationAccuracy,
    CallNumber,
    Calendar,
    OpenNativeSettings,
    SmsRetriever,
    ToastComponent,
    AlertComponent,
    SplashScreen,Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
