// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCuXYB8xE9XgQzIOMMRJAVfyiDui1vXr2M",
    authDomain: "fixitnowapp.firebaseapp.com",
    databaseURL: "https://fixitnowapp.firebaseio.com",
    projectId: "fixitnowapp",
    storageBucket: "fixitnowapp.appspot.com",
    messagingSenderId: "69100913669",
    appId: "1:69100913669:web:398f7cb6b95c56d429c86c",
    measurementId: "G-8HXCSRWZLL"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
